// Set main fonts
$header-font: "'Lato', sans-serif";
$body-font: "'Source Sans Pro', sans-serif";


// https://coolors.co/4c4c57-565656-bcb8b1-f4f3ee-ff4a1c
/* SCSS HEX */
$dark-liver: #4C4C57;
$davys-grey: #565656;
$pale-silver: #bcb8b1;
$alabaster: #f4f3ee;
$red-orange-color-wheel: #ff4a1c;

// Background colours
$backgroundColour: $dark-liver;
$codeBackgroundColour: #fafafa;
$featureBackgroundColour: #f9fafa;
$accentColour: $red-orange-color-wheel;

// Text colours
$headingColour: $red-orange-color-wheel;
$bodyColour: $alabaster;
$linkColour: $red-orange-color-wheel;
$hoverColour: $alabaster;
$focusColour: $red-orange-color-wheel;
$captionColour: $alabaster;
$white: #ECF0F1;

// Typography
$bodytype: (
  font-family: $body-font,
  regular: 400,
  bold: 700,
  italic: italic,
  cap-height: 0.75
);

$headingtype: (
  font-family: $header-font,
  regular: 400,
  bold: 700,
  cap-height: 0.75
);

$monospacetype: (
  font-family: "Menlo, monospace",
  regular: 400,
  cap-height: 0.68
);

@import "alembic";

@import url('https://fonts.googleapis.com/css?family=Lato|Source+Sans+Pro&display=swap');


*, ::after, ::before {
    box-sizing: border-box;
}
.collapse {
  display: none;
&.show {
    display: block;
  }
}
